import { IProductOneProposal } from '@contexts/product-one/types';
import { IProductTwoProposal } from '@contexts/product-two/types';
import { ICurrentMarket, IPremises, ISampleMonth, ITargetMarket, IUnitReport } from '@pages/detailed-analysis/types';

const getCurrentMarketUnitTableDataMediumVoltage = (unitReport: IUnitReport) => {
  const currentMarketUnitTableDataMediumVoltage = [
    {
      label: 'Demanda Ponta (kW)',
      value: unitReport.premises.acrContractedDemandPeak,
      tooltipContent: 'propriedade: unitReport.premises.acrContractedDemandPeak',
    },
    {
      label: 'Demanda Ponta (R$/kW)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.demandPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.demandPeak',
    },
    {
      label: 'Total Demanda Ponta (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.demandPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.demandPeak',
    },
    {
      label: 'Demanda Fora Ponta (kW)	',
      value: unitReport.premises.acrContractedDemandOffPeak,
      tooltipContent: 'propriedade: unitReport.premises.acrContractedDemandOffPeak',
    },
    {
      label: 'Demanda Fora Ponta (R$/kW)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.demandOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.demandOffPeak',
    },
    {
      label: 'Total Demanda Fora Ponta (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.demandOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.demandOffPeak',
    },
    {
      label: 'TUSD Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak',
    },
    {
      label: 'TUSD Ponta (R$/MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffPeak',
    },
    {
      label: 'Total TUSD Ponta (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.distributionTariffPeakCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffPeakCost',
    },
    {
      label: 'TUSD Fora Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak',
    },
    {
      label: 'TUSD Fora Ponta (R$/MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'Total TUSD Fora Ponta (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.distributionTariffOffPeakCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffOffPeakCost',
    },
    {
      label: 'TE Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionPeak',
    },
    {
      label: 'TE Ponta (R$/MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffPeak',
    },
    {
      label: 'Total TE Ponta (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.electricityTariffPeakCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffPeakCost',
    },
    {
      label: 'TE Fora Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionOffPeak',
    },
    {
      label: 'TE Fora Ponta (R$/MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffOffPeak',
    },
    {
      label: 'Total TE Fora Ponta (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.electricityTariffOffPeakCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffOffPeakCost',
    },
    {
      label: 'Total custo gerador (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.generatorCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.generatorCost',
    },
    {
      label: 'Total Bandeiras Tarifárias',
      value: unitReport.sampleMonthSimulation.currentMarket.tariffFlag,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariffFlag',
    },
    {
      label: 'ICMS',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms',
    },
    {
      label: 'PIS/COFINS',
      value: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins',
    },
    {
      label: 'Total sem impostos (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes',
    },
    {
      label: 'Total com impostos (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes',
    },
  ];

  return currentMarketUnitTableDataMediumVoltage;
};

const getCurrentMarketUnitTableDataLowVoltage = (unitReport: IUnitReport) => {
  const currentMarketUnitTableDataLowVoltage = [
    {
      label: 'TUSD (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage',
    },
    {
      label: 'TUSD (R$/MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffLowVoltage,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.distributionTariffLowVoltage',
    },
    {
      label: 'Total TUSD (R$)	custo com TUSD',
      value: unitReport.sampleMonthSimulation.currentMarket.distributionTariffLowVoltageCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.distributionTariffLowVoltageCost',
    },
    {
      label: 'TE (MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.consumptionLowVoltage',
    },
    {
      label: 'TE (R$/MWh)',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffLowVoltage,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.electricityTariffLowVoltage',
    },
    {
      label: 'Total TE (R$)',
      value: unitReport.sampleMonthSimulation.currentMarket.electricityTariffLowVoltageCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.electricityTariffLowVoltageCost',
    },
    {
      label: 'bandeira tarifária',
      value: unitReport.sampleMonthSimulation.currentMarket.tariffFlag,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariffFlag',
    },
    {
      label: 'ICMS',
      value: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue',
    },
    {
      label: 'Valor Total de Consumo sem Impostos',
      value: unitReport.sampleMonthSimulation.currentMarket.rawTotalConsumptionCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.rawTotalConsumptionCost',
    },
    {
      label: 'Valor Total de Consumo com ICMS',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithIcms',
    },
    {
      label: 'PIS COFINS',
      value: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalPisCofins',
    },
    {
      label: 'Valor Total sem Taxas',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithoutTaxes',
    },
    {
      label: 'Valor Total com Taxas',
      value: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.totalWithTaxes',
    },
  ];

  return currentMarketUnitTableDataLowVoltage;
};

export const getCurrentMarketUnitTableData = (unitReport: IUnitReport) => {
  const currentMarketUnitTableData =
    unitReport.premises.tariff == 8 || unitReport.premises.tariff == 9
      ? getCurrentMarketUnitTableDataLowVoltage(unitReport)
      : getCurrentMarketUnitTableDataMediumVoltage(unitReport);

  return currentMarketUnitTableData;
};

export const getTargetMarketUnitTableData = (unitReport: IUnitReport) => {
  const targetMarketUnitTableData = [
    {
      label: 'Demanda Ponta (kW)	',
      value: unitReport.premises.aclContractedDemandPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandPeak',
    },
    {
      label: 'Demanda Ponta (R$/kW)',
      value: unitReport.sampleMonthSimulation.targetMarket.tariff.demandPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.demandPeak',
    },
    {
      label: 'Total Demanda Ponta (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.demandPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandPeak',
    },
    {
      label: 'Demanda Fora Ponta (kW)	',
      value: unitReport.premises.aclContractedDemandOffPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandOffPeak',
    },
    {
      label: 'Demanda Fora Ponta (R$/kW)',
      value: unitReport.sampleMonthSimulation.targetMarket.tariff.demandOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.demandOffPeak',
    },
    {
      label: 'Total Demanda Fora Ponta (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.demandOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandOffPeak',
    },
    {
      label: 'TUSD Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.targetMarket.consumptionPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.consumptionPeak',
    },
    {
      label: 'TUSD Ponta (R$/MWh)',
      value: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffPeak',
    },
    {
      label: 'Total TUSD Ponta (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakCost',
    },
    {
      label: 'TUSD Fora Ponta (MWh)',
      value: unitReport.sampleMonthSimulation.targetMarket.consumptionOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.consumptionOffPeak',
    },
    {
      label: 'TUSD Fora Ponta (R$/MWh)',
      value: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.tariff.distributionTariffOffPeak',
    },
    {
      label: 'Total TUSD Fora Ponta (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.distributionTariffOffPeakCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffOffPeakCost',
    },
    {
      label: 'Desconto Demanda Ponta',
      value: unitReport.sampleMonthSimulation.targetMarket.demandPeakDiscount,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandPeakDiscount',
    },
    {
      label: 'Desconto Demanda Fora Ponta',
      value: unitReport.sampleMonthSimulation.targetMarket.demandOffPeakDiscount,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.demandOffPeakDiscount',
    },
    {
      label: 'Desconto TUSD Ponta',
      value: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakDiscount,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionTariffPeakDiscount',
    },
    {
      label: 'ICMS distribuição',
      value: unitReport.sampleMonthSimulation.targetMarket.distributionIcms,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionIcms',
    },
    {
      label: 'ICMS eletricidade',
      value: unitReport.sampleMonthSimulation.targetMarket.electricityIcms,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityIcms',
    },
    {
      label: 'ICMS',
      value:
        unitReport.sampleMonthSimulation.targetMarket.distributionIcms +
        unitReport.sampleMonthSimulation.targetMarket.electricityIcms,
      tooltipContent:
        'propriedade:  unitReport.sampleMonthSimulation.targetMarket.distributionIcms + unitReport.sampleMonthSimulation.targetMarket.electricityIcms	',
    },
    {
      label: 'PIS/COFINS',
      value: unitReport.sampleMonthSimulation.targetMarket.distributionPisCofins,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.distributionPisCofins',
    },
    {
      label: 'Energia com perdas (MWh)',
      value: unitReport.sampleMonthSimulation.targetMarket.electricityWithLosses,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityWithLosses',
    },
    {
      label: 'Destaque PIS/COFINS',
      value: unitReport.sampleMonthSimulation.targetMarket.pisCofinsHighlight,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.pisCofinsHighlight',
    },
    {
      label: 'Total Energia (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.electricityTariffCost,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.electricityTariffCost',
    },
    {
      label: 'ESS/EER',
      value: unitReport.sampleMonthSimulation.targetMarket.securityAndModulationEnergyCharges,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.securityAndModulationEnergyCharges',
    },
    {
      label: 'Contribuição CCEE',
      value: unitReport.sampleMonthSimulation.targetMarketContributionCcee,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarketContributionCcee',
    },
    {
      label: 'Gestão Clarke',
      value: unitReport.premises.managementPrice,
      tooltipContent: 'propriedade: unitReport.premises.managementPrice',
    },
    {
      label: 'Total sem impostos (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.totalWithoutTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.totalWithoutTaxes',
    },
    {
      label: 'Total com impostos (R$)',
      value: unitReport.sampleMonthSimulation.targetMarket.totalWithTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.totalWithTaxes',
    },
  ];

  return targetMarketUnitTableData;
};

export const getEconomyUnitTableData = (unitReport: IUnitReport) => {
  const economyUnitTableData = [
    {
      label: 'Economia Total (R$)',
      value: unitReport?.sampleMonthSimulation?.report?.totalSavingWithTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.report.totalSavingWithTaxes',
    },
    {
      label: 'Economia Total %',
      value: unitReport?.sampleMonthSimulation?.report?.totalSavingInPercentageWithTaxes,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.report.totalSavingInPercentageWithTaxes',
    },
  ];

  return economyUnitTableData;
};

export const getPremissisesTableData = (unitReport: IUnitReport) => {
  const premissesTableData = [
    {
      label: 'Data de migração',
      value: unitReport.premises.migrationDate,
      tooltipContent: 'propriedade: unitReport.premises.migrationDate',
    },
    {
      label: 'Distribuidora',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.energyDistributionCompany.name,
      tooltipContent:
        'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.energyDistributionCompany.name',
    },

    {
      label: 'Modalidade Tarifária',
      value: unitReport.sampleMonthSimulation.currentMarket.tariff.tariff,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.tariff.tariff',
    },
    {
      label: 'Demanda Ponta ACR',
      value: unitReport.premises.aclContractedDemandPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandPeak',
    },
    {
      label: 'Demanda Fora Ponta ACR',
      value: unitReport.premises.aclContractedDemandOffPeak,
      tooltipContent: 'propriedade: unitReport.premises.aclContractedDemandOffPeak',
    },
    {
      label: 'Classificação da empresa',
      value: unitReport.premises.icmsType,
      tooltipContent: 'propriedade: unitReport.premises.icmsType',
    },
    {
      label: 'ICMS',
      value: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.currentMarket.icms.percentageValue',
    },
    {
      label: 'Tipo de energia',
      value: 'Incentivada 50%',
      tooltipContent: 'Valor padrão',
    },
    {
      label: 'Gestão Clarke/mês',
      value: unitReport.premises.managementPrice,
      tooltipContent: 'propriedade: unitReport.premises.managementPrice',
    },
    {
      label: 'Migração',
      value: unitReport.premises.initialExpenses,
      tooltipContent: 'propriedade: unitReport.premises.initialExpenses',
    },
    {
      label: 'Contribuição CCEE',
      value: unitReport.sampleMonthSimulation.targetMarket.contributionCcee,
      tooltipContent: 'propriedade: unitReport.sampleMonthSimulation.targetMarket.contributionCcee',
    },
  ];

  return premissesTableData;
};

const monthsListDescription = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const getConsumptionMediumVoltageTableData = (unitReport: IUnitReport) => {
  const consumptionPeak = unitReport.premises.consumptionPeak;
  const consumptionOffPeak = unitReport.premises.consumptionOffPeak;

  const parsedConsumptioMediumVoltage = monthsListDescription.map((item, index) => ({
    label: item,
    consumptionPeak: consumptionPeak[index + 1],
    consumptionOffPeak: consumptionOffPeak[index + 1],
  }));
  return parsedConsumptioMediumVoltage;
};

export const getConsumptionLowVoltageTableData = (unitReport: IUnitReport) => {
  const consumptionLowVoltage = unitReport.premises.consumptionLowVoltage;

  const parsedConsumptionLowVoltage = Object.entries(consumptionLowVoltage).map(([key, value]) => ({
    label: monthsListDescription[parseInt(key) - 1],
    value: value,
  }));

  return parsedConsumptionLowVoltage;
};

export const getPriceListTableData = (unitReport: IUnitReport) => {
  const pricelist = Object.values(unitReport.priceList)[0];
  const parsedPriceList = Object.entries(pricelist).map(([key, value]) => ({
    label: key,
    value: value,
  }));
  return parsedPriceList;
};

export function parseProposalToUnitsReportsRetail(proposal: IProductTwoProposal): IUnitReport[] {
  return proposal?.calculatorMetadata?.default?.bestRetailTraderResult?.calculatorMetadata?.unitsReports.map(
    (unit: any) => {
      const premises: IPremises = {
        nickname: unit?.premises?.nickname,
        icmsType: unit?.premises?.icmsType,
        migrationDate: unit?.premises?.migrationDate,
        managementPrice: unit?.premises?.managementPrice,
        initialExpenses: unit?.premises?.initialExpenses,
        powerGeneratorCost: unit?.premises?.powerGeneratorCost,
        solarPlantInOperation: unit?.premises?.solarPlantInOperation,
        consumptionPeak: unit?.premises?.consumptionPeak,
        consumptionOffPeak: unit?.premises?.consumptionOffPeak,
        consumptionLowVoltage: unit?.premises?.consumptionLowVoltage,
        companyType: unit?.premises?.companyType,
        operationHoursRange: unit?.premises?.operationHoursRange,
        distributedGenerationMonthlyCost: unit?.premises?.distributedGenerationMonthlyCost,
        injectedEnergyOffPeak: unit?.premises?.injectedEnergyOffPeak,
        injectedEnergyPeak: unit?.premises?.injectedEnergyPeak,
        manualAcrContractedDemandPeak: unit?.premises?.manualAcrContractedDemandPeak,
        manualAcrContractedDemandOffPeak: unit?.premises?.manualAcrContractedDemandOffPeak,
        manualAclContractedDemandPeak: unit?.premises?.manualAclContractedDemandPeak,
        manualAclContractedDemandOffPeak: unit?.premises?.manualAclContractedDemandOffPeak,
        manualConsumptionOffPeak: unit?.premises?.manualConsumptionOffPeak,
        manualConsumptionPeak: unit?.premises?.manualConsumptionPeak,
        targetVolumeGenerated: unit?.premises?.targetVolumeGenerated,
        energyDistributionCompany: unit?.premises?.energyDistributionCompany,
        acrContractedDemandPeak: unit?.premises?.acrContractedDemandPeak,
        acrContractedDemandOffPeak: unit?.premises?.acrContractedDemandOffPeak,
        aclContractedDemandPeak: unit?.premises?.aclContractedDemandPeak,
        aclContractedDemandOffPeak: unit?.premises?.aclContractedDemandOffPeak,
        tariff: unit?.premises?.tariff,
      };

      const currentMarket: ICurrentMarket = {
        consumptionPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionPeak,
        consumptionOffPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionOffPeak,
        distributionTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffPeakCost,
        distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffOffPeakCost,
        electricityTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffPeakCost,
        electricityTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffOffPeakCost,
        generatorCost: unit?.sampleMonthSimulation?.currentMarket?.generatorCost,
        demandOffPeak: unit?.sampleMonthSimulation?.currentMarket?.demandOffPeak,
        demandPeak: unit?.sampleMonthSimulation?.currentMarket?.demandPeak,
        consumptionLowVoltage: unit?.sampleMonthSimulation?.currentMarket?.consumptionLowVoltage,
        tariff: unit?.sampleMonthSimulation?.currentMarket?.tariff,
        distributionTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffLowVoltageCost,
        electricityTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffLowVoltageCost,
        monthYear: unit?.sampleMonthSimulation?.currentMarket?.monthYear,
        generalGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.generalGeneratorFactor,
        tariffFlagList: unit?.sampleMonthSimulation?.currentMarket?.tariffFlagList,
        targetMigrationDate: unit?.sampleMonthSimulation?.currentMarket?.targetMigrationDate,
        injectedEnergyOffPeak: unit?.sampleMonthSimulation?.currentMarket?.injectedEnergyOffPeak,
        monthGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.monthGeneratorFactor,
        icms: unit?.sampleMonthSimulation?.currentMarket?.icms,
        rawTotalConsumptionCost: unit?.sampleMonthSimulation?.currentMarket?.rawTotalConsumptionCost,
        totalWithIcms: unit?.sampleMonthSimulation?.currentMarket?.totalWithIcms,
        totalPisCofins: unit?.sampleMonthSimulation?.currentMarket?.totalPisCofins,
        totalWithoutTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithoutTaxes,
        totalWithTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
        tariffFlag: unit?.sampleMonthSimulation?.currentMarket?.tariffFlag,
      };

      const targetMarket: ITargetMarket = {
        demandOffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeakDiscount,
        demandPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandPeakDiscount,
        risk: unit?.sampleMonthSimulation?.targetMarket?.risk,
        productType: unit?.sampleMonthSimulation?.targetMarket?.productType,
        monthYear: unit?.sampleMonthSimulation?.targetMarket?.monthYear,
        priceList: unit?.sampleMonthSimulation?.targetMarket?.priceList,
        customTaxes: unit?.sampleMonthSimulation?.targetMarket?.customTaxes,
        icms: unit?.sampleMonthSimulation?.targetMarket?.icms,
        manualAclPrice: unit?.sampleMonthSimulation?.targetMarket?.manualAclPrice,
        manualAclPricesHasChargesCoverage: unit?.sampleMonthSimulation?.targetMarket?.manualAclPricesHasChargesCoverage,
        contributionCcee: unit?.sampleMonthSimulation?.targetMarket?.contributionCcee,
        modulation: unit?.sampleMonthSimulation?.targetMarket?.modulation,
        demandPeak: unit?.sampleMonthSimulation?.targetMarket?.demandPeak,
        demandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeak,
        tariff: unit?.sampleMonthSimulation?.targetMarket?.tariff,
        consumptionPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionPeak,
        consumptionOffPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionOffPeak,
        distributionTariffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakCost,
        distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffOffPeakCost,
        distributionTariffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakDiscount,
        stimulatedDemandPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandPeak,
        stimulatedDemandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandOffPeak,
        stimulatedDistributionTariffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDistributionTariffPeak,
        distributionIcms: unit?.sampleMonthSimulation?.targetMarket?.distributionIcms,
        electricityIcms: unit?.sampleMonthSimulation?.targetMarket?.electricityIcms,
        distributionPisCofins: unit?.sampleMonthSimulation?.targetMarket?.distributionPisCofins,
        electricityWithLosses: unit?.sampleMonthSimulation?.targetMarket?.electricityWithLosses,
        pisCofinsHighlight: unit?.sampleMonthSimulation?.targetMarket?.pisCofinsHighlight,
        electricityTariffCost: unit?.sampleMonthSimulation?.targetMarket?.electricityTariffCost,
        securityAndModulationEnergyCharges:
          unit?.sampleMonthSimulation?.targetMarket?.securityAndModulationEnergyCharges,
        targetMarketContributionCcee: unit?.sampleMonthSimulation?.targetMarket?.targetMarketContributionCcee,
        clarkeManagement: unit?.sampleMonthSimulation?.targetMarket?.clarkeManagement,
        totalWithoutTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithoutTaxes,
        monthGeneratorFactor: unit?.sampleMonthSimulation?.targetMarket?.monthGeneratorFactor,
        totalWithTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
        managementPrice: unit?.sampleMonthSimulation?.targetMarket?.managementPrice,
      };

      const sampleMonth: ISampleMonth = {
        currentMarket,
        targetMarket,
        report: unit?.sampleMonthSimulation.report,
      };

      const unitReport: IUnitReport = {
        premises,
        totalSavingWithTaxes: unit?.totalSavingWithTaxes,
        totalSavingInPercentageWithTaxes: unit?.totalSavingInPercentageWithTaxes,
        averageConsumption: unit?.averageConsumption,
        carbonDioxideEmissionInTonsAvoidedByYear: unit?.carbonDioxideEmissionInTonsAvoidedByYear,
        currentSpentByYear: unit?.currentSpentByYear,
        averageBillAmount: unit?.averageBillAmount,
        energyFeeMonthlyByYear: unit?.energyFeeMonthlyByYear,
        grossMerchandiseVolumeByYear: unit?.grossMerchandiseVolumeByYear,
        initialExpenses: unit?.initialExpenses,
        managementPriceByYear: unit?.managementPriceByYear,
        manualAclPrice: unit?.manualAclPrice,
        nickname: unit?.nickname,
        priceList: unit?.priceList,
        rawPriceList: unit?.rawPriceList,
        usedPriceProjection: unit?.usedPriceProjection,
        sampleMonthSimulation: unit?.sampleMonthSimulation,
        savingByYearInPercentage: unit?.savingByYearInPercentage,
        savingByYear: unit?.savingByYear,
        savingByMonth: unit?.savingByMonth,
        takeRateByYear: unit?.takeRateByYear,
        targetSpentByYear: unit?.targetSpentByYear,
        totalCarbonDioxideEmissionInTonsAvoided: unit?.totalCarbonDioxideEmissionInTonsAvoided,
        totalGrossMerchandiseVolume: unit?.totalGrossMerchandiseVolume,
        totalManagementPrice: unit?.totalManagementPrice,
        totalMonths: unit?.totalMonths,
        totalSavingInPercentage: unit?.totalSavingInPercentage,
        totalSaving: unit?.totalSaving,
        totalTakeRate: unit?.totalTakeRate,
        totalConsumptionSummationInMwh: unit?.totalConsumptionSummationInMwh,
        totalLifetimeValue: unit?.totalLifetimeValue,
      };

      return unitReport;
    },
  );
}

export function parseProposalToUnitsReportsManagement(proposal: IProductOneProposal): IUnitReport[] {
  return proposal?.calculatorMetadata?.firstResult.unitsReports.map((unit: any) => {
    const premises: IPremises = {
      nickname: unit?.premises?.nickname,
      icmsType: unit?.premises?.icmsType,
      migrationDate: unit?.premises?.migrationDate,
      managementPrice: unit?.premises?.managementPrice,
      initialExpenses: unit?.premises?.initialExpenses,
      powerGeneratorCost: unit?.premises?.powerGeneratorCost,
      solarPlantInOperation: unit?.premises?.solarPlantInOperation,
      consumptionPeak: unit?.premises?.consumptionPeak,
      consumptionOffPeak: unit?.premises?.consumptionOffPeak,
      consumptionLowVoltage: unit?.premises?.consumptionLowVoltage,
      companyType: unit?.premises?.companyType,
      operationHoursRange: unit?.premises?.operationHoursRange,
      distributedGenerationMonthlyCost: unit?.premises?.distributedGenerationMonthlyCost,
      injectedEnergyOffPeak: unit?.premises?.injectedEnergyOffPeak,
      injectedEnergyPeak: unit?.premises?.injectedEnergyPeak,
      manualAcrContractedDemandPeak: unit?.premises?.manualAcrContractedDemandPeak,
      manualAcrContractedDemandOffPeak: unit?.premises?.manualAcrContractedDemandOffPeak,
      manualAclContractedDemandPeak: unit?.premises?.manualAclContractedDemandPeak,
      manualAclContractedDemandOffPeak: unit?.premises?.manualAclContractedDemandOffPeak,
      manualConsumptionOffPeak: unit?.premises?.manualConsumptionOffPeak,
      manualConsumptionPeak: unit?.premises?.manualConsumptionPeak,
      targetVolumeGenerated: unit?.premises?.targetVolumeGenerated,
      energyDistributionCompany: unit?.premises?.energyDistributionCompany,
      acrContractedDemandPeak: unit?.premises?.acrContractedDemandPeak,
      acrContractedDemandOffPeak: unit?.premises?.acrContractedDemandOffPeak,
      aclContractedDemandPeak: unit?.premises?.aclContractedDemandPeak,
      aclContractedDemandOffPeak: unit?.premises?.aclContractedDemandOffPeak,
      tariff: unit?.premises?.tariff,
    };

    const currentMarket: ICurrentMarket = {
      consumptionPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.currentMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffOffPeakCost,
      electricityTariffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffPeakCost,
      electricityTariffOffPeakCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffOffPeakCost,
      generatorCost: unit?.sampleMonthSimulation?.currentMarket?.generatorCost,
      demandOffPeak: unit?.sampleMonthSimulation?.currentMarket?.demandOffPeak,
      demandPeak: unit?.sampleMonthSimulation?.currentMarket?.demandPeak,
      consumptionLowVoltage: unit?.sampleMonthSimulation?.currentMarket?.consumptionLowVoltage,
      tariff: unit?.sampleMonthSimulation?.currentMarket?.tariff,
      distributionTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.distributionTariffLowVoltageCost,
      electricityTariffLowVoltageCost: unit?.sampleMonthSimulation?.currentMarket?.electricityTariffLowVoltageCost,
      monthYear: unit?.sampleMonthSimulation?.currentMarket?.monthYear,
      generalGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.generalGeneratorFactor,
      tariffFlagList: unit?.sampleMonthSimulation?.currentMarket?.tariffFlagList,
      targetMigrationDate: unit?.sampleMonthSimulation?.currentMarket?.targetMigrationDate,
      injectedEnergyOffPeak: unit?.sampleMonthSimulation?.currentMarket?.injectedEnergyOffPeak,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.currentMarket?.monthGeneratorFactor,
      icms: unit?.sampleMonthSimulation?.currentMarket?.icms,
      rawTotalConsumptionCost: unit?.sampleMonthSimulation?.currentMarket?.rawTotalConsumptionCost,
      totalWithIcms: unit?.sampleMonthSimulation?.currentMarket?.totalWithIcms,
      totalPisCofins: unit?.sampleMonthSimulation?.currentMarket?.totalPisCofins,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithoutTaxes,
      totalWithTaxes: unit?.sampleMonthSimulation?.currentMarket?.totalWithTaxes,
      tariffFlag: unit?.sampleMonthSimulation?.currentMarket?.tariffFlag,
    };

    const targetMarket: ITargetMarket = {
      demandOffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeakDiscount,
      demandPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.demandPeakDiscount,
      risk: unit?.sampleMonthSimulation?.targetMarket?.risk,
      productType: unit?.sampleMonthSimulation?.targetMarket?.productType,
      monthYear: unit?.sampleMonthSimulation?.targetMarket?.monthYear,
      priceList: unit?.sampleMonthSimulation?.targetMarket?.priceList,
      customTaxes: unit?.sampleMonthSimulation?.targetMarket?.customTaxes,
      icms: unit?.sampleMonthSimulation?.targetMarket?.icms,
      manualAclPrice: unit?.sampleMonthSimulation?.targetMarket?.manualAclPrice,
      manualAclPricesHasChargesCoverage: unit?.sampleMonthSimulation?.targetMarket?.manualAclPricesHasChargesCoverage,
      contributionCcee: unit?.sampleMonthSimulation?.targetMarket?.contributionCcee,
      modulation: unit?.sampleMonthSimulation?.targetMarket?.modulation,
      demandPeak: unit?.sampleMonthSimulation?.targetMarket?.demandPeak,
      demandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.demandOffPeak,
      tariff: unit?.sampleMonthSimulation?.targetMarket?.tariff,
      consumptionPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionPeak,
      consumptionOffPeak: unit?.sampleMonthSimulation?.targetMarket?.consumptionOffPeak,
      distributionTariffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakCost,
      distributionTariffOffPeakCost: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffOffPeakCost,
      distributionTariffPeakDiscount: unit?.sampleMonthSimulation?.targetMarket?.distributionTariffPeakDiscount,
      stimulatedDemandPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandPeak,
      stimulatedDemandOffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDemandOffPeak,
      stimulatedDistributionTariffPeak: unit?.sampleMonthSimulation?.targetMarket?.stimulatedDistributionTariffPeak,
      distributionIcms: unit?.sampleMonthSimulation?.targetMarket?.distributionIcms,
      electricityIcms: unit?.sampleMonthSimulation?.targetMarket?.electricityIcms,
      distributionPisCofins: unit?.sampleMonthSimulation?.targetMarket?.distributionPisCofins,
      electricityWithLosses: unit?.sampleMonthSimulation?.targetMarket?.electricityWithLosses,
      pisCofinsHighlight: unit?.sampleMonthSimulation?.targetMarket?.pisCofinsHighlight,
      electricityTariffCost: unit?.sampleMonthSimulation?.targetMarket?.electricityTariffCost,
      securityAndModulationEnergyCharges: unit?.sampleMonthSimulation?.targetMarket?.securityAndModulationEnergyCharges,
      targetMarketContributionCcee: unit?.sampleMonthSimulation?.targetMarket?.targetMarketContributionCcee,
      clarkeManagement: unit?.sampleMonthSimulation?.targetMarket?.clarkeManagement,
      totalWithoutTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithoutTaxes,
      monthGeneratorFactor: unit?.sampleMonthSimulation?.targetMarket?.monthGeneratorFactor,
      totalWithTaxes: unit?.sampleMonthSimulation?.targetMarket?.totalWithTaxes,
      managementPrice: unit?.sampleMonthSimulation?.targetMarket?.managementPrice,
    };

    const sampleMonth: ISampleMonth = {
      currentMarket,
      targetMarket,
      report: unit?.sampleMonthSimulation.report,
    };

    const unitReport: IUnitReport = {
      premises,
      totalSavingWithTaxes: unit?.totalSavingWithTaxes,
      totalSavingInPercentageWithTaxes: unit?.totalSavingInPercentageWithTaxes,
      averageConsumption: unit?.averageConsumption,
      carbonDioxideEmissionInTonsAvoidedByYear: unit?.carbonDioxideEmissionInTonsAvoidedByYear,
      currentSpentByYear: unit?.currentSpentByYear,
      averageBillAmount: unit?.averageBillAmount,
      energyFeeMonthlyByYear: unit?.energyFeeMonthlyByYear,
      grossMerchandiseVolumeByYear: unit?.grossMerchandiseVolumeByYear,
      initialExpenses: unit?.initialExpenses,
      managementPriceByYear: unit?.managementPriceByYear,
      manualAclPrice: unit?.manualAclPrice,
      nickname: unit?.nickname,
      priceList: unit?.priceList,
      rawPriceList: unit?.rawPriceList,
      usedPriceProjection: unit?.usedPriceProjection,
      sampleMonthSimulation: unit?.sampleMonthSimulation,
      savingByYearInPercentage: unit?.savingByYearInPercentage,
      savingByYear: unit?.savingByYear,
      savingByMonth: unit?.savingByMonth,
      takeRateByYear: unit?.takeRateByYear,
      targetSpentByYear: unit?.targetSpentByYear,
      totalCarbonDioxideEmissionInTonsAvoided: unit?.totalCarbonDioxideEmissionInTonsAvoided,
      totalGrossMerchandiseVolume: unit?.totalGrossMerchandiseVolume,
      totalManagementPrice: unit?.totalManagementPrice,
      totalMonths: unit?.totalMonths,
      totalSavingInPercentage: unit?.totalSavingInPercentage,
      totalSaving: unit?.totalSaving,
      totalTakeRate: unit?.totalTakeRate,
      totalConsumptionSummationInMwh: unit?.totalConsumptionSummationInMwh,
      totalLifetimeValue: unit?.totalLifetimeValue,
    };

    return unitReport;
  });
}
