import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import useWhiteLabel from '@hooks/use-white-label';

import { SuppliersProjectionsHistoryTableHead } from '@components/atoms/form/table/head';
import { SuppliersProjectionsHistoryTableBody } from '@components/molecules/white-label/body';
import ContractLayoutBase from '@components/organisms/general/white-label-supplier/layout-base';
import NewPagination from '@components/atoms/general/pagination';

export interface ISuppliesProjections {
  id: string;
  retail_trader_id: string;
  user: {
    id: string;
    name: string;
    picture_url: string;
  };
  expiration_date: string;
  created_at: string;
  charges: null;
  supplies: {
    id: string;
    name: string;
    users: string;
  }[];
}

export function WhiteLabelHistory() {
  const { allWhiteLabelSuppliers, getAllWhiteLabelSuppliers, isLoading } = useWhiteLabel();
  const [currentPage, setCurrentPage] = useState(1);
  const [renderSuppliersProjections, setRenderSuppliersProjections] = useState<boolean>(false);

  useEffect(() => {
    getAllWhiteLabelSuppliers && getAllWhiteLabelSuppliers(currentPage);
  }, [currentPage, renderSuppliersProjections]);

  return (
    <ContractLayoutBase title="Fornecedoras White Label" contractType="WHITE_LABEL" contractFlow="WHITE_LABEL_LIST">
      <>
        {allWhiteLabelSuppliers && !isLoading ? (
          <>
            <table className="grid m-5 table-auto">
              <SuppliersProjectionsHistoryTableHead />
              <SuppliersProjectionsHistoryTableBody
                suppliersProjections={allWhiteLabelSuppliers.data}
                setRenderSuppliersProjections={setRenderSuppliersProjections}
                renderSuppliersProjections={renderSuppliersProjections}
              />
            </table>
            <div className="mb-5">
              <NewPagination
                currentPage={allWhiteLabelSuppliers.data.page}
                rowsByPage={allWhiteLabelSuppliers.data.limit}
                totalRows={allWhiteLabelSuppliers.data.total}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </>
        ) : (
          <div className="grid m-5 table-auto">
            <Skeleton count={5} height={30} />
          </div>
        )}
      </>
    </ContractLayoutBase>
  );
}
