import { twMerge } from 'tailwind-merge';
import { useState } from 'react';
import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';

export function DropdownSection({
  title,
  children,
  className,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={twMerge(!className && 'border-2 p-2 flex flex-col')}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={twMerge(
          className,
          'flex gap-4 w-full text-left py-2 px-4 rounded-md focus:outline-none bg-gray-100 hover:bg-gray-200 ',
        )}
      >
        <HeroIconSolidOrOutline
          className="w-6 h-6 text-gray-500"
          useSolid={true}
          icon={isOpen ? 'FolderOpenIcon' : 'FolderIcon'}
        />
        {title}
      </button>
      {isOpen && <div className="p-6">{children}</div>}
    </div>
  );
}
