import { addWorkdays, startDate } from '@utils/datetime';
import { IconProps } from '@clarke-energia/foton';
import { IProductTwoProposal } from '@contexts/product-two/types';
import { formatNumber } from '@utils/numeral';

interface IRetailcardDetailProps {
  headerContent: {
    title: string;
    migrationImage: IconProps['icon'];
    managementImage: IconProps['icon'];
  };
  retailPrice: number;
  migrationCost: number;
  retailTableCardData: {
    headerContent: {
      title: string;
      image: IconProps['icon'];
    };
    annualData: Array<{
      year: string;
      price: string;
    }>;
  };
}

export function createRetailCardData(
  calculatorMetadata: IProductTwoProposal['calculatorMetadata'],
): IRetailcardDetailProps {
  const proposal = calculatorMetadata?.default?.bestRetailTraderResult?.calculatorMetadata;
  const inputs = calculatorMetadata?.default?.bestRetailTraderResult?.inputs;
  const totalMonths = proposal?.unitsReports[0]?.totalMonths || 1;
  Object.keys(proposal?.grossMerchandiseVolumeByYear || {}).map((year) => {
    const supply = proposal?.unitsReports[0].rawPriceList![Object.keys(proposal?.unitsReports[0].rawPriceList!)[0]][
      year
    ] as number;
    return {
      year,
      economy: proposal?.totalSavingByYear[year],
      captiveMarket: proposal?.totalCurrentSpentByYear[year],
      freeMarket: proposal?.totalTargetSpentByYear[year],
      supply,
    };
  });
  return {
    headerContent: {
      title: 'Serviço de Migração',
      migrationImage: 'ArrowsRightLeftIcon',
      managementImage: 'UserIcon',
    },
    // TODO remove conditional after 2 weeks of implementation
    retailPrice: proposal?.totalMonthlyManagementCost
      ? proposal.totalMonthlyManagementCost
      : inputs?.units &&
        inputs?.units.reduce((accumulator, unit) => {
          return accumulator + unit.managementPrice;
        }, 0),
    migrationCost: proposal?.totalMigrationCost ?? 0,
    retailTableCardData: {
      headerContent: {
        title: 'Preços de energia',
        image: 'BoltIcon',
      },
      annualData: Object.keys(proposal?.grossMerchandiseVolumeByYear || {}).map((year) => {
        const supply = proposal?.unitsReports[0].rawPriceList![Object.keys(proposal?.unitsReports[0].rawPriceList!)[0]][ // TODO -> REVIEW THIS
          year
        ] as number;
        return {
          year,
          price: formatNumber(supply, '$ 00,0.00'),
        };
      }),
    },
  };
}
interface IProposalDetail {
  proposalValidity: string | undefined;
  totalMigrationCost: number | undefined;
  totalManagementPrice: number;
  discountInPercentage?: number;
  discountTotalMigrationCost?: number;
  discountTotalMigrationPrice?: number;
}

export function generateDescription(proposalDetail: IProposalDetail) {
  const discount = proposalDetail?.discountInPercentage;
  const endDate = addWorkdays(startDate(), 2).format('DD/MM/YYYY');

  if (discount) {
    return `${formatNumber(discount, '%')} de desconto válido até ${endDate}`;
  } else {
    return `Válido até ${endDate}`;
  }
}
