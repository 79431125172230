import { SuppliersProjectionsHistoryTableAtoms as TableAtoms } from '@components/atoms/form/table';

interface ISuppliersProjectionsHistoryTableBody {
  suppliersProjections: any;
  setRenderSuppliersProjections: React.Dispatch<React.SetStateAction<boolean>>;
  renderSuppliersProjections: boolean;
}
export function SuppliersProjectionsHistoryTableBody({
  suppliersProjections,
  setRenderSuppliersProjections,
  renderSuppliersProjections,
}: ISuppliersProjectionsHistoryTableBody) {
  return (
    <tbody>
      {suppliersProjections &&
        suppliersProjections?.data
          ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          ?.map((supplieProjection: any, index: number) => (
            <tr
              key={`price-projection-history-${index}`}
              className="grid grid-cols-10 items-center gap-4 w-full h-8 border-b-1 border-brand-gray-20  text-paragraph-small"
            >
              <TableAtoms.ResponsibleColumn suppliersProjection={supplieProjection} />
              <TableAtoms.TagColumn suppliersProjection={supplieProjection} />
              <TableAtoms.ButtonsColumn
                suppliersProjection={supplieProjection}
                setRenderSuppliersProjections={setRenderSuppliersProjections}
                renderSuppliersProjections={renderSuppliersProjections}
              />
            </tr>
          ))}
    </tbody>
  );
}
