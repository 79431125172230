import { twMerge } from 'tailwind-merge';
import { HeroIcon } from '@clarke-energia/foton';

import useWhiteLabel from '@hooks/use-white-label';
import { IManagementcardDetailProps } from '@components/organisms/new-proposal/management/types';
import { formatNumber } from '@utils/numeral';

import './styles.css';

interface IServiceDetail {
  title: string;
  price?: number;
  isCardDistacled?: boolean;
}

function ServiceDetail({ title, price, isCardDistacled }: IServiceDetail) {
  const { isWhiteLabelProposal } = useWhiteLabel();

  const colorMain = `text-white-label-main`;
  const colorbrighter = `text-white-label-brighter`;

  return (
    <span className="management-card-service-detail">
      <h2 className={twMerge('management-card-title', isWhiteLabelProposal && `text-white-label-brighter`)}>{title}</h2>
      <p
        className={twMerge(
          `management-card-price`,
          isCardDistacled && 'text-primary-60 font-bold',
          isCardDistacled && isWhiteLabelProposal && `${colorMain}`,
          !isCardDistacled && isWhiteLabelProposal && `${colorbrighter}`,
        )}
      >
        {Number(price) > 0
          ? `${formatNumber(Number(price), '$ 00,0.00')} ${title === 'Serviço de migração' ? 'à vista' : '/mês'}`
          : 'Sem custo'}
      </p>
    </span>
  );
}

function ManagementCard({
  headerContent,
  migrationCost,
  managementPrice,
  isCardDistacled,
}: IManagementcardDetailProps) {
  const { isWhiteLabelProposal } = useWhiteLabel();

  const colorMain = isWhiteLabelProposal ? 'text-white-label-main' : 'text-primary-80';
  const colorbrighter = isWhiteLabelProposal ? 'text-white-label-brighter' : '';
  const iconColorWhiteLabel = isWhiteLabelProposal && isCardDistacled ? colorMain : colorbrighter;
  const iconColor = isCardDistacled && 'text-primary-60';
  return (
    <div className="management-card-container">
      <div className="management-card-header">
        <div>
          {headerContent.imageSvg && headerContent.imageSvg !== '' && !isWhiteLabelProposal ? (
            <img
              className={`management-card-icon ${!isWhiteLabelProposal ? iconColor : iconColorWhiteLabel}`}
              src={headerContent.imageSvg}
              alt="icon-img"
            />
          ) : (
            headerContent.image && (
              <HeroIcon
                icon={headerContent.image}
                className={`management-card-icon ${!isWhiteLabelProposal ? iconColor : iconColorWhiteLabel}`}
              />
            )
          )}
        </div>
        <span className="management-card-title-container">
          <strong
            className={twMerge(
              'management-card-title',
              isCardDistacled && 'text-primary-60 w-full',
              isCardDistacled && isWhiteLabelProposal && 'text-white-label-main',
              isCardDistacled && !isWhiteLabelProposal ? 'text-primary-60' : '',
              !isCardDistacled && isWhiteLabelProposal && colorbrighter,
            )}
          >
            {headerContent.title}
          </strong>
          {headerContent.description && <p className="management-card-description">{headerContent.description}</p>}
        </span>
      </div>
      <div className="management-card-details">
        <ServiceDetail title="Serviço de migração" price={migrationCost} isCardDistacled={isCardDistacled} />
        <ServiceDetail title="Serviço de gestão" price={managementPrice} isCardDistacled={isCardDistacled} />
      </div>
    </div>
  );
}

function ManagementCardDetail({ ManagementcardData }: { ManagementcardData: IManagementcardDetailProps[] }) {
  return (
    <div className="management-card-detail-container">
      <div className="management-card-detail-wrapper">
        {ManagementcardData.map((cardDetail) => (
          <ManagementCard key={cardDetail.headerContent.title} {...cardDetail} />
        ))}
      </div>
    </div>
  );
}

export default ManagementCardDetail;
