import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { validationDatePricesExpirationDate } from '@utils/datetime';
import { IWhiteLabelSupplierPresentation } from '@contexts/white-label/type';
import useWhiteLabel from '@hooks/use-white-label';

import heroLogo from '@assets/newProposal/heroLogo.svg';
import SectionProposalHeroContainer from '@components/atoms/new-proposal/hero-container/hero';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import './styles.css';

interface IPresentation extends Omit<IWhiteLabelSupplierPresentation, 'email' | 'phone'> {}
interface CoverSectionProps {
  pricesExpirationDate?: string;
  leadName?: string;
}

function CoverSection({ pricesExpirationDate, leadName }: CoverSectionProps) {
  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  const [presentation, setPresentation] = useState<IPresentation>();

  const overHeaderText = () => {
    const textPrefix = 'Proposta de Economia';

    if (isWhiteLabelProposal) return;
    else if (isManagementPath) return `${textPrefix} | Clarke Gestão`;
    else return `${textPrefix} | Clarke Varejo`;
  };

  useEffect(() => {
    if (whiteLabelSupplier) {
      const { presentation } = whiteLabelSupplier;
      setPresentation(presentation);
    }
  }, [whiteLabelSupplier]);

  return (
    <SlideContainer className={twMerge('min-h-fit h-screen', !isManagementPath && 'gradient-container')}>
      <SectionProposalHeroContainer
        isCoverMain={true}
        className="sm:flex-row h-full xl:min-h-100"
        darkTheme={!isManagementPath}
      >
        <div className="main-container min-h-full">
          {isWhiteLabelProposal ? (
            <img
              className="logo-style w-auto max-w-48 rounded-xl"
              src={presentation?.logoUrl as string}
              alt={`logo da fornecedor ${presentation?.name}`}
            />
          ) : (
            <img src={heroLogo} alt="Logo da Clarke" className={'logo-style w-2/4 max-w-56'} />
          )}

          <span className="text-container">
            <span className="pl-5 md:w-full sm:ml-10 mb-5 sm:mb-12">
              <p
                style={{
                  color: `${isWhiteLabelProposal && 'var(--text-white-label-main)'}`,
                }}
                className={'text-heading-xsmall md:text-heading-medium xl:text-heading-large font-light'}
              >
                {overHeaderText()}
              </p>
              <h1
                style={{
                  color: `${isWhiteLabelProposal && 'var(--text-white-label-main)'}`,
                }}
                className="text-heading-large md:text-display-medium xl:text-display-large font-bold mb-5"
              >
                {leadName}
              </h1>
            </span>

            <p
              className={`${
                isWhiteLabelProposal
                  ? 'text-heading-small text-white-label-main'
                  : 'text-paragraph-medium sm:text-heading-small'
              }`}
            >
              Válido até {validationDatePricesExpirationDate(pricesExpirationDate)}
            </p>
          </span>
        </div>
      </SectionProposalHeroContainer>
    </SlideContainer>
  );
}

export default CoverSection;
