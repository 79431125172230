import { useState } from 'react';
import { TARIFF } from '@utils/constants';
import {
  getConsumptionLowVoltageTableData,
  getConsumptionMediumVoltageTableData,
  getCurrentMarketUnitTableData,
  getEconomyUnitTableData,
  getPremissisesTableData,
  getPriceListTableData,
  getTargetMarketUnitTableData,
} from '@pages/detailed-analysis/helper';
import { IUnitReport } from '@pages/detailed-analysis/types';
import { DropdownSection } from './dropdonw';
import { DataTable } from './dataTable';

export function GenerateTablesByUnit({ units }: { units: Array<IUnitReport> }) {
  const [selectedUnit, setSelectedUnit] = useState<IUnitReport | null>(null);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedUnit(selectedValue === 'all' ? null : units.find((unit) => unit?.nickname === selectedValue) || null);
  };

  const filteredUnits = selectedUnit ? [selectedUnit] : units;

  return (
    <>
      <div className="mb-6">
        <label htmlFor="unit-select" className="block mb-2 text-sm font-medium text-gray-700">
          Selecione uma unidade:
        </label>
        <select
          id="unit-select"
          onChange={handleSelectChange}
          className="bg-primary-60 rounded-full w-52 px-5 py-4 border "
        >
          <option className="bg-primary-10 rounded-se-md rounded-tl-md" value="all">
            Todas as Unidades
          </option>
          {units.map((unit) => (
            <option
              className="bg-primary-10 py-4 rounded-se-md rounded-tl-md"
              key={unit?.nickname}
              value={unit?.nickname}
            >
              {unit?.nickname}
            </option>
          ))}
        </select>
      </div>

      {filteredUnits.map((unit, index) => {
        const currentMarketUnitTableData = getCurrentMarketUnitTableData(unit);
        const targetMarketUnitTableData = getTargetMarketUnitTableData(unit);
        const economyUnitTableData = getEconomyUnitTableData(unit);
        const premissisesTableData = getPremissisesTableData(unit);
        const consumptionMediumVoltageTableData = getConsumptionMediumVoltageTableData(unit);
        const consumptionLowVoltageTableData =
          unit.premises.tariff == TARIFF.B3_OPTING || unit.premises.tariff == TARIFF.B3_STANDARD
            ? getConsumptionLowVoltageTableData(unit)
            : null;
        const priceListTableData = getPriceListTableData(unit);
        return (
          <DropdownSection className="text-paragraph-large font-semibold " key={index} title={unit?.premises?.nickname}>
            <h2 className="text-lg font-semibold">Análise detalhada da Unidade: {`${unit?.nickname}`}</h2>
            <div className="flex gap-6 flex-col p-6 border border-gray-300 rounded-lg shadow-md bg-white">
              <DropdownSection className="text-paragraph-large font-semibold " title="Premissas de Cálculo">
                <DropdownSection className="mb-5" title="Premissas">
                  <DataTable data={premissisesTableData} firstColumnName="Premissa" />
                </DropdownSection>

                <DropdownSection className="mb-5" title="Consumo Média Tensão">
                  <DataTable data={consumptionMediumVoltageTableData} firstColumnName="Mês" />
                </DropdownSection>

                {consumptionLowVoltageTableData && (
                  <DropdownSection className="mb-5" title="Consumo Baixa Tensão">
                    <DataTable data={consumptionLowVoltageTableData} firstColumnName="Mês" />
                  </DropdownSection>
                )}

                <DropdownSection className="mb-5" title="Lista de Preços">
                  <DataTable data={priceListTableData} firstColumnName="Ano" />
                </DropdownSection>
              </DropdownSection>

              <DropdownSection
                className="text-paragraph-large font-semibold "
                title={`Resultado de Simulação - ${unit?.sampleMonthSimulation?.report?.monthLabel}/${unit?.sampleMonthSimulation?.report?.year}`}
              >
                <DropdownSection className="mb-5" title="Dados do Mercado Cativo">
                  <DataTable data={currentMarketUnitTableData} />
                </DropdownSection>

                <DropdownSection className="mb-5" title="Dados do Mercado Livre">
                  <DataTable data={targetMarketUnitTableData} />
                </DropdownSection>

                <DropdownSection className="mb-5" title="Dados de Economia">
                  <DataTable data={economyUnitTableData} />
                </DropdownSection>
              </DropdownSection>
            </div>
          </DropdownSection>
        );
      })}
    </>
  );
}
