import React from 'react';

import { Button, FeedbackNotification, FeedbackNotificationProps, Modal } from '@clarke-energia/foton';
import { Link, useNavigate } from 'react-router-dom';

import { TARIFF } from '@utils/constants';
import { convertBooleanToString, getCalculatorName } from '@utils/common';

import { useAuth } from '@src/auth-wrapper';
import { GENERAL_CALCULATOR_PATH } from '@routers/constants';

import { SimulatorTypeEnum } from '@contexts/types';
import useCustomClipboard from '@hooks/use-custom-clipboard';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getMigrationStartYear } from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';
import CreateProposalDocumentButton from '@components/atoms/general/create-proposal-document-button';
import SimulationDocumentGenerationResult from '@components/atoms/general/simulation-document-generation-result';
import ReuseSimulationForm from '@components/molecules/form/reuse-simulation/reuse-simulation-form';

import ModalDeleteProposal from './modal-delete-proposal';

export interface IProposalPreviewFooter {
  simulatorType: keyof typeof SimulatorTypeEnum;
  simulatorPath: string;
  inputsValuesToCopy: Record<string, any>;
  leadName?: string;
  simulationId: string;
  isProposalComplete?: boolean;
  leadId?: string;
  selectedProposalId: string | undefined;
  setSelectedProposalId: React.Dispatch<React.SetStateAction<string | undefined>>;
  onClickDeleteProposal: (leadId: string, selectedProposalId: string) => Promise<void>;
  feedbackNotificationsContent?: FeedbackNotificationProps;
}

const LeadProposalsHubFooter = ({
  simulatorType,
  simulatorPath,
  simulationId,
  leadName,
  inputsValuesToCopy,
  isProposalComplete,
  leadId,
  selectedProposalId,
  setSelectedProposalId,
  onClickDeleteProposal,
  feedbackNotificationsContent,
}: IProposalPreviewFooter): JSX.Element => {
  const navigate = useNavigate();
  const { CopyFormValues } = useCustomClipboard();

  const { user } = useAuth();

  const [pdfLink, setPdfLink] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [modalReuseSimulationOpen, setModalReuseSimulationOpen] = React.useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);

  const simulationUrl = simulationId && `${simulatorPath}/${simulationId}`;
  const simulationSimplifiedPropostaUrl = `${simulatorPath}/proposta-simplificada/${simulationId}`;
  const redoSimulationUrl = `${simulatorPath}/${GENERAL_CALCULATOR_PATH}?refazer-calculo`;
  const buttonVisibleSimplifiedResult =
    SimulatorTypeEnum[simulatorType] === SimulatorTypeEnum.CLARKE_MANAGEMENT ||
    SimulatorTypeEnum[simulatorType] === SimulatorTypeEnum.CLARKE_RETAIL;

  const handleButtonFullProposalClick = () => {
    eventTracker.trackEvent(eventLabels.BUTTON_FULL_PROPOSAL_DRAWER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  const handleButtonSimplifiedProposalClick = () => {
    eventTracker.trackEvent(eventLabels.BUTTON_SIMPLIFIED_PROPOSAL_DRAWER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  const handleButtonDetailedAnalisysClick = () => {
    eventTracker.trackEvent(eventLabels.BUTTON_DETAILED_ANALYSIS, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  const convertTimeFormat = (time: string) => {
    const hours = time.slice(0, 2);
    const minutes = time.slice(3, 5);
    return `${hours}${minutes}`;
  };

  const parseOperationHoursRange = (inputsValuesToCopy: IProposalPreviewFooter['inputsValuesToCopy']) => {
    const units = inputsValuesToCopy.units;
    for (const unit of units) {
      if (unit.tariff === TARIFF.B3_STANDARD || unit.tariff === TARIFF.B3_OPTING) {
        const { operationHoursRange } = unit;
        Object.entries(operationHoursRange).map((key: Record<string, any>) => {
          if (key[1] != null) {
            key[1].openingTime = convertTimeFormat(key[1].openingTime);
            key[1].closingTime = convertTimeFormat(key[1].closingTime);
          }
        });
      }
    }
  };

  const parseHasManualPrices = (inputsValuesToCopy: IProposalPreviewFooter['inputsValuesToCopy']) => {
    const manualAclPrice = inputsValuesToCopy.manualAclPrice;
    if (manualAclPrice) {
      inputsValuesToCopy.hasManualPrice = true;
    }
  };

  const parseManualPricesOrDiscountsStartYear = (inputsValuesToCopy: IProposalPreviewFooter['inputsValuesToCopy']) => {
    inputsValuesToCopy.manualPricesOrDiscountsStartYear = getMigrationStartYear(inputsValuesToCopy.units);
  };
  const parseHasChargeCoverage = (inputsValuesToCopy: IProposalPreviewFooter['inputsValuesToCopy']) => {
    const manualAclPricesHasChargesCoverage = inputsValuesToCopy.manualAclPricesHasChargesCoverage;
    if (manualAclPricesHasChargesCoverage !== undefined) {
      inputsValuesToCopy.manualAclPricesHasChargesCoverage = convertBooleanToString(manualAclPricesHasChargesCoverage);
    }
  };
  const parseInputsValuesToCopy = (inputsValuesToCopy: IProposalPreviewFooter['inputsValuesToCopy']) => {
    parseOperationHoursRange(inputsValuesToCopy);
    inputsValuesToCopy.manualAclPrice && parseHasChargeCoverage(inputsValuesToCopy);
    inputsValuesToCopy.manualAclPrice && parseHasManualPrices(inputsValuesToCopy);
    parseManualPricesOrDiscountsStartYear(inputsValuesToCopy);
  };

  const handleButtonRepeatSimulationlClick = () => {
    parseManualPricesOrDiscountsStartYear(inputsValuesToCopy);
    parseInputsValuesToCopy(inputsValuesToCopy);
    CopyFormValues(inputsValuesToCopy);
    navigate(redoSimulationUrl);
    eventTracker.trackEvent(eventLabels.BUTTON_REPEAT_SIMULATION_DRAWER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  const handleButtonReuseProposalClick = () => {
    setModalReuseSimulationOpen(true);

    eventTracker.trackEvent(eventLabels.BUTTON_REUSE_PROPOSAL_DRAWER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  const handleButtonDeleteProposalClick = () => {
    setOpenModalDelete(true);
    setSelectedProposalId(simulationId);
    eventTracker.trackEvent(eventLabels.BUTTON_DELETE_PROPOSAL_DRAWER, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });
  };

  return (
    <div className="w-full h-fit flex mb-2">
      <div className="min-w-full grid justify-normal gap-6">
        <div className="min-w-full flex flex-row flex-wrap  gap-x-4 gap-y-6">
          {simulationUrl && (
            <>
              <Link to={simulationUrl} target="_blank" rel="noopener noreferrer">
                <Button
                  kind="primary"
                  label="Proposta completa"
                  size="small"
                  disabled={!isProposalComplete}
                  onClick={handleButtonFullProposalClick}
                  className="btn btn-green"
                />
              </Link>
              {buttonVisibleSimplifiedResult && (
                <Link to={simulationSimplifiedPropostaUrl} target="_blank" rel="noopener noreferrer">
                  <Button
                    kind="primary"
                    label="Proposta simplificada"
                    size="small"
                    disabled={!isProposalComplete}
                    onClick={handleButtonSimplifiedProposalClick}
                    className="btn btn-green"
                  />
                </Link>
              )}
              <CreateProposalDocumentButton
                simulatorType={simulatorType}
                isButtonSmallSize={true}
                disabled={!isProposalComplete}
                {...{ simulationUrl, leadName, setPdfLink, setErrorMessage }}
              />
            </>
          )}
        </div>
        <div className="min-w-full flex flex-row flex-wrap  gap-x-4 gap-y-6">
          <Button
            kind="secondary"
            label="Refazer Simulação"
            size="small"
            onClick={() => {
              handleButtonRepeatSimulationlClick();
            }}
          />
          {!user?.isCommercialDealer && !user?.isWhiteLabel && (
            <Button
              kind="secondary"
              label="Reaproveitar proposta"
              size="small"
              onClick={() => {
                handleButtonReuseProposalClick();
              }}
              disabled={!isProposalComplete}
            />
          )}
          <Link to={`${simulatorPath}/${simulationId}/analise-detalhada`} target="_blank" rel="noopener noreferrer">
            <Button
              kind="secondary"
              label="Análise Detalhada"
              size="small"
              onClick={() => {
                handleButtonDetailedAnalisysClick();
              }}
            />
          </Link>
          <Button
            className="border-danger-60 text-danger-60 bg-white hover:bg-red-50"
            icon="TrashIcon"
            label="  "
            size="small"
            onClick={() => {
              handleButtonDeleteProposalClick();
            }}
          />
        </div>
        <SimulationDocumentGenerationResult pdfLink={pdfLink} errorMessage={errorMessage} />
        {feedbackNotificationsContent && (
          <div className="fixed right-0 -bottom-12- my-6 mx-6 max-w-full lg:w-fit z-[200]">
            <FeedbackNotification {...feedbackNotificationsContent} />
          </div>
        )}
      </div>
      <Modal
        open={modalReuseSimulationOpen}
        setOpen={() => setModalReuseSimulationOpen((previous) => !previous)}
        title="Reaproveitar Simulação"
        description="Gere novas simulações utilizando outros simuladores do sistema."
      >
        <ReuseSimulationForm simulatorType={simulatorType} simulatorInputs={inputsValuesToCopy} />
      </Modal>
      <div>
        <ModalDeleteProposal
          leadId={leadId}
          selectedProposalId={selectedProposalId}
          onClickDeleteProposal={onClickDeleteProposal}
          openModalDelete={openModalDelete}
          setOpenModalDelete={setOpenModalDelete}
        />
      </div>
    </div>
  );
};
export default LeadProposalsHubFooter;
