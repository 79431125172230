import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import { GenerateTablesByUnit } from '@components/atoms/general/generateTablesByUnits';
import useProductTwo from '@hooks/use-product-two';
import { TARIFF } from '@utils/constants';
import { parseProposalToUnitsReportsRetail, parseProposalToUnitsReportsManagement } from './helper';
import useProductOne from '@hooks/use-product-one';
import { IProductOneProposal } from '@contexts/product-one/types';

export function DetailedAnalysis() {
  const { getProductTwoProposalById, proposal: productTwoProposal } = useProductTwo();
  const { getProductOneProposalById, proposal: productOneProposal } = useProductOne();

  const isManagementPath = window.location.pathname.includes('clarke-gestao');
  const isRetailPath = window.location.pathname.includes('clarke-varejo');

  const unitsReport = isRetailPath
    ? parseProposalToUnitsReportsRetail(productTwoProposal)
    : parseProposalToUnitsReportsManagement(productOneProposal as IProductOneProposal);
  const { id } = useParams();
  useEffect(() => {
    isRetailPath && getProductTwoProposalById(id);
    isManagementPath && getProductOneProposalById(id as string);
  }, [id]);
  const lowVoltageUnits = unitsReport?.filter(
    (unit) => unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING,
  );
  const mediumVoltageUnits = unitsReport?.filter(
    (unit) => !(unit?.premises?.tariff === TARIFF?.B3_STANDARD || unit?.premises?.tariff === TARIFF?.B3_OPTING),
  );
  const gridCols = lowVoltageUnits?.length > 0 && mediumVoltageUnits?.length > 0 ? 2 : 1;

  return (
    <SlideContainer className="px-5 py-10">
      <>
        <LegendContainer className="mb-6" fontSizeInPX={40} legend="Análise Detalhada" />
        <div className="flex flex-col gap-10">
          <div className={`grid grid-cols-${gridCols} gap-x-8`}>
            {lowVoltageUnits?.length > 0 && (
              <div className="flex flex-col relative">
                <h3 className="text-lg font-semibold mb-4 flex items-center">Unidades Baixa Tensão</h3>
                <div className="flex flex-col gap-5">
                  <GenerateTablesByUnit units={lowVoltageUnits} />
                </div>
              </div>
            )}
            {mediumVoltageUnits?.length > 0 && (
              <div className="flex flex-col relative ">
                <h3 className="text-lg font-semibold mb-4 flex items-center">Unidades Média Tensão</h3>
                <div className="flex flex-col gap-5">
                  <GenerateTablesByUnit units={mediumVoltageUnits} />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </SlideContainer>
  );
}
