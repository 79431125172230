import { Tooltip } from '@clarke-energia/foton';
import { IObjectKeys } from '@pages/detailed-analysis/types';
import { formatNumber } from '@utils/numeral';
import { isNumber } from 'lodash';

enum DescriptionColumn {
  value = 'Valor',
  consumptionOffPeak = 'Consumo Fora Ponta (KWh)',
  consumptionPeak = 'Consumo Ponta (KWh)',
  label = 'Item',
}

function parseValue(value: string | number | null | undefined) {
  if (typeof value === 'string') {
    return value;
  }
  if (isNumber(value)) {
    return formatNumber(value, '0,0.00');
  }
  return '-';
}

interface DataTableProps {
  data: IObjectKeys[];
  firstColumnName?: string;
}

export function DataTable({ data, firstColumnName = 'Item' }: DataTableProps) {
  const renderHeader = () => (
    <thead className="bg-gray-100">
      <tr>
        {Object.keys(data[0]).map((key, i) => {
          if (key !== 'tooltipContent') {
            const translatedKey = DescriptionColumn[key as keyof typeof DescriptionColumn];
            return (
              <th key={i} className="py-2 px-4 border border-gray-300 text-left">
                {firstColumnName && i === 0 ? firstColumnName : translatedKey}
              </th>
            );
          }
          return null;
        })}
      </tr>
    </thead>
  );

  const renderRow = (item: IObjectKeys, i: number) => (
    <tr key={i} className="hover:bg-gray-50">
      <td className="py-2 px-4 border border-gray-300 text-left">
        <span className="flex items-center gap-10 justify-between pr-6">
          {item.label}
          {item.tooltipContent && <Tooltip content={item.tooltipContent} />}
        </span>
      </td>
      {!(item.consumptionOffPeak && item.consumptionPeak) && (
        <td className="py-2 px-4 border border-gray-300 text-left">{parseValue(item.value)}</td>
      )}
      {item.consumptionPeak && (
        <td className="py-2 px-4 border border-gray-300 text-left">{parseValue(item.consumptionPeak)}</td>
      )}
      {item.consumptionOffPeak && (
        <td className="py-2 px-4 border border-gray-300 text-left">{parseValue(item.consumptionOffPeak)}</td>
      )}
    </tr>
  );

  return (
    <table className="min-w-full border border-gray-200">
      {renderHeader()}
      <tbody>{data.map((item, i) => renderRow(item, i))}</tbody>
    </table>
  );
}
