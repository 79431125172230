import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { UserGroup, UserInfo } from '@contexts/users/types';

export interface AuthenticationState {
  isAuthenticated: boolean;
  accessToken?: string;
}

export interface IAuthContext {
  authStatus: AuthenticationState;
  user?: UserInfo;
  logout: () => void;
  loading: boolean;
}

export const authContext = React.createContext({} as IAuthContext);
export const useAuth = () => React.useContext<IAuthContext>(authContext);

interface AuthWrapperProps {
  children: JSX.Element;
}

function AuthWrapper({ children }: AuthWrapperProps) {
  const [token, setToken] = React.useState<string>();
  const { getAccessTokenSilently, isAuthenticated, user, logout, isLoading } = useAuth0();

  const parseUserInfo = () => {
    if (user && token) {
      const userGroups = user?.['https://clarke.com.br/groups'] as Array<UserGroup>;
      const parsedUserInfo: UserInfo = {
        id: user['https://clarke.com.br/uuid'],
        name: user.name,
        email: user.email,
        pictureUrl: user.picture,
        isSuperAdmin: userGroups.includes('Admin Especial de Vendas'),
        isAdmin: userGroups.includes('Vendas Admin'),
        isCommercialDealer: userGroups.includes('Vendas Revendedor'),
        isWhiteLabel: userGroups.includes('Vendas White Label'),
        roles: user['https://clarke.com.br/roles'],
        groups: userGroups,
        phone: user.phone_number,
        token,
      };
      return parsedUserInfo;
    }
  };

  const contextValue: IAuthContext = {
    authStatus: { isAuthenticated, accessToken: token },
    user: parseUserInfo(),
    logout,
    loading: isLoading,
  };

  React.useEffect(() => {
    const getToken = async () => {
      const bearerToken = isAuthenticated ? await getAccessTokenSilently() : '';
      setToken(bearerToken);
    };

    getToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return <authContext.Provider value={contextValue}>{children}</authContext.Provider>;
}

export default AuthWrapper;
