import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { LayoutProps, Modal } from '@clarke-energia/foton';

import { GENERAL_SUPPLIERS_PATH, HOME_PATH, WHITE_LABEL_PATH } from '@routers/constants';

import { useAuth } from '@src/auth-wrapper';
import useWhiteLabel from '@hooks/use-white-label';
import { IWhiteLabelSupplierPayload } from '@contexts/white-label/type';
import { WhiteLabelSupplierFormCore } from './formcore';

interface IWhiteLabelSupplierFormContent extends LayoutProps {
  formController: UseFormReturn<IWhiteLabelSupplierPayload, any>;
}
interface SuccessModalProps {
  open: boolean;
  setOpen: () => void;
  message: {
    title: string;
    description: string;
  };
  isUpdate: boolean;
}

interface ErrorModalProps {
  open: boolean;
  setOpen: () => void;
  message: {
    title: string;
    description: string;
  };
  isUpdate: boolean;
}

export function WhiteLabelSupplierFormContent({ formController }: IWhiteLabelSupplierFormContent) {
  const { user } = useAuth();
  const isUpdateWhiteLabelSupplierPath = window.location.pathname.includes('editar');
  const {
    whiteLabelSupplier,
    createNewWhiteLabelSupplier,
    updateWhiteLabelSupplier,
    openSuccessModalWhiteLabel,
    setOpenSuccessModalWhiteLabel,
    openErrorModalWhiteLabel,
    setOpenErrorModalWhiteLabel,
  } = useWhiteLabel();
  const navigate = useNavigate();
  const onSubmit = async (data: IWhiteLabelSupplierPayload) => {
    const removeEmpytUsers = data?.users?.filter((user) => user?.id && user?.email);
    try {
      if (user?.isSuperAdmin && data.users.length) {
        data.users = removeEmpytUsers;
      }
      const supplierData = isUpdateWhiteLabelSupplierPath
        ? { ...data, id: whiteLabelSupplier.id, createdAt: whiteLabelSupplier?.createdAt }
        : data;

      const success = isUpdateWhiteLabelSupplierPath
        ? await updateWhiteLabelSupplier(supplierData, whiteLabelSupplier.id)
        : await createNewWhiteLabelSupplier(supplierData);

      setOpenSuccessModalWhiteLabel(success);
      setOpenErrorModalWhiteLabel(!success);
    } catch (error) {
      setOpenErrorModalWhiteLabel(false);
    }
  };
  const SuccessModal: React.FC<SuccessModalProps> = ({ open, setOpen, message }) => (
    <Modal
      open={open}
      setOpen={setOpen}
      title={message.title}
      id="success-modal"
      customAttrs={{ 'data-cy': 'success-modal' }}
      description={message.description}
      modalButtons={{
        primary: {
          customAttrs: { 'data-cy': 'success-modal-button' },
          label: 'OK, ciente',
          onClick: () => {
            setOpenSuccessModalWhiteLabel(false);
            navigate(`${WHITE_LABEL_PATH}/${GENERAL_SUPPLIERS_PATH}`);
          },
        },
      }}
    />
  );
  const ErrorModal: React.FC<ErrorModalProps> = ({ open, setOpen, message }) => (
    <Modal
      customAttrs={{ 'data-cy': 'error-modal' }}
      className="bg-red-500"
      open={open}
      setOpen={setOpen}
      title={message.title}
      id="error-modal"
      description={message.description}
      modalButtons={{
        primary: {
          customAttrs: { 'data-cy': 'error-modal-button' },
          label: 'Revisar o formulário',
          color: 'red',
          onClick: () => {
            setOpenErrorModalWhiteLabel(false);
          },
        },
      }}
    />
  );
  const handleModalClose = (type?: 'ERROR' | 'SUCCESS') => {
    if (!type) return;
    if (type === 'ERROR') {
      setOpenErrorModalWhiteLabel(false);
    }
    if (type === 'SUCCESS') {
      setOpenSuccessModalWhiteLabel(!openSuccessModalWhiteLabel);
      navigate(HOME_PATH);
    }
  };

  const successMessage = isUpdateWhiteLabelSupplierPath
    ? {
        title: 'Atualização realizada com sucesso',
        description: 'O cadastro da sua fornecedora foi atualizado com sucesso.',
      }
    : { title: 'Cadastro realizado com sucesso', description: 'O cadastro da sua fornecedora foi criado com sucesso.' };

  const errorMessage = isUpdateWhiteLabelSupplierPath
    ? {
        title: 'Erro ao atualizar fornecedora',
        description: 'Ocorreu um erro ao atualizar a fornecedora. Tente novamente.',
      }
    : { title: 'Erro ao criar fornecedora', description: 'Ocorreu um erro ao criar a fornecedora. Tente novamente.' };
  return (
    <div className="col-span-full flex flex-col w-full  p-7">
      <FormProvider {...formController}>
        <WhiteLabelSupplierFormCore formController={formController} onSubmit={onSubmit} />
        {openSuccessModalWhiteLabel && (
          <SuccessModal
            open={openSuccessModalWhiteLabel}
            setOpen={() => handleModalClose('SUCCESS')}
            message={successMessage}
            isUpdate={isUpdateWhiteLabelSupplierPath}
          />
        )}
        {openErrorModalWhiteLabel && (
          <ErrorModal
            open={openErrorModalWhiteLabel}
            setOpen={() => handleModalClose('ERROR')}
            message={errorMessage}
            isUpdate={isUpdateWhiteLabelSupplierPath}
          />
        )}
      </FormProvider>
    </div>
  );
}
