import { twMerge } from 'tailwind-merge';
import { useFormContext } from 'react-hook-form';
import { IWhiteLabelSupplierPayload } from '@contexts/white-label/type';
import useWhiteLabel from '@hooks/use-white-label';
import ContractSectionContainer from '@components/atoms/form/contract/contract-section-container';
import ContractTitleAndSubtitleSubsection from '@components/atoms/form/contract/contract-title-and-subtitle-subsection';
import { FileField } from '@components/atoms/form/file/input';
import { RenderSupplierPresentation } from '@components/atoms/form/contract/supplier-presentation';
import { RenderSupplierColors } from '@components/atoms/form/contract/supplier-color';

const defaultInputsOption = {
  presentation: {
    name: '',
    phone: '',
    email: '',
  },
  colors: {
    main: '',
    darker: '',
    brighter: '',
  },
};

export function WhiteLabelSupplierFormPresentationSection() {
  const { whiteLabelSupplier } = useWhiteLabel();
  const isUpdatePath = window.location.pathname.includes('editar/');
  const { setValue } = useFormContext<IWhiteLabelSupplierPayload>();
  const { colors } = whiteLabelSupplier || defaultInputsOption;
  const presentaion = {
    name: whiteLabelSupplier?.presentation?.name,
    phone: whiteLabelSupplier?.presentation?.phone,
    email: whiteLabelSupplier?.presentation?.email,
  };

  const currentLogo = whiteLabelSupplier?.presentation?.logoUrl;
  return (
    <ContractSectionContainer>
      <div className="grid grid-cols-3 col-span-full min-w-full">
        <ContractTitleAndSubtitleSubsection
          title="Sobre a empresa"
          subtitle="Informações da fornecedora e sua identidade visual."
        />
        <div className="col-span-2 grid grid-cols-2">
          <div className="col-span-2 grid grid-cols-2 gap-6 items-center">
            <RenderSupplierPresentation presentation={presentaion} required={isUpdatePath} />
            <RenderSupplierColors colors={colors} required={isUpdatePath} />
          </div>

          <div className="grid grid-cols-2 min-w-full h-full col-span-full mt-5">
            {currentLogo && (
              <div className="grid grid-cols-1 justify-start items-start relative">
                <p className="flex justify-start items-center text-paragraph-medium text-black h-8">Logo atual</p>
                <img src={currentLogo} alt="logo" className="h-3/5 absolute top-7 left-0 rounded-xl" />
              </div>
            )}
            <div className={twMerge(currentLogo ? 'col-span-1 pl-5' : 'col-span-full', 'pt-1')}>
              <FileField<IWhiteLabelSupplierPayload>
                field="fileList"
                id="fileList"
                label={'Nova Logo'}
                description={'Envie a imagem da logo com fundo transparente no formato PNG de até 5MB.'}
                maximumFileSizeInMB={5}
                allowedFileTypes={['.png']}
                onChangeFiles={(files) => {
                  setValue('fileList', files as File);
                }}
                multipleFiles={false}
                options={{
                  required: isUpdatePath && currentLogo ? false : true,
                  validate: (value) => {
                    const inputFile = value as File;
                    if (!currentLogo && isUpdatePath && value) {
                      return inputFile.size > 0 && inputFile.type.toLowerCase().includes('png')
                        ? true
                        : 'Anexe uma imagem PNG';
                    }
                    return true;
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ContractSectionContainer>
  );
}
